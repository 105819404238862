import React from "react"
import { Layout, SEO } from "../components"
import CategoriesList from "../components/categories/categories-list"

const AllCategories = () => {
  return (
    <Layout>
      <SEO title="All Categories" />
      <CategoriesList />
    </Layout>
  )
}

export default AllCategories
